import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/about.css";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SecondaryHeader from "../../components/Header/SecondaryHeader";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import News from "../../components/News/News";
import ArrowIcon, {
	AboutBannerImage,
	AboutImage1,
	AboutImage2,
	DiversityImage,
	VideoThumbnail,
} from "../../constant/Index";

const About = () => {
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* BreadCrumbs Start Here */}
			<Breadcrumbs currentPage="About Us" />
			{/* BreadCrumbs End Here */}

			{/* InnerBanner Start Here */}
			<InnerBanner
				heading="About Us"
				description="HaiTrack is a global provider of professional information, software solutions, and services for clinicians, accountants, lawyers, and tax, finance, audit, risk, compliance, and regulatory sectors."
				btnText="Our heritage"
				bannerImage={AboutBannerImage}
			/>
			{/* InnerBanner End Here */}

			{/* Secondary Banner Start Here */}
			<SecondaryHeader />
			{/* Secondary Banner End Here */}

			{/* Motivational Note Sec Start Here */}
			<section className="motivational-note">
				<div className="container">
					<h2>Deliver deep impact when it matters most</h2>
					<p>
						HaiTrack reported 2021 annual revenues of €4.8 billion. The group
						serves customers in over 180 countries, maintains operations in over
						40 countries, and employs approximately 19,800 people worldwide. Our
						customers work in industries which impact the lives of millions of
						people every single day. Our mission is to empower our professional
						customers with the information, software solutions, and services
						they need to make critical decisions, achieve successful outcomes,
						and save time. Our expert solutions combine deep domain knowledge
						with technology to deliver both content and workflow automation to
						drive improved outcomes and productivity for our customers. We are
						committed to helping professionals improve the way they do business
						and solve complex problems with our range of digital solutions and
						services, which we continuously evolve to meet their changing needs.
						Our over 185-year legacy and portfolio represent thousands of
						customers worldwide.
					</p>
				</div>
			</section>
			{/* Motivational Note Sec End Here */}

			{/* About Wolter Sec Start Here */}
			<section className="about_volter">
				<div className="container">
					<div className="heading-wrapper">
						<h3>About HaiTrack</h3>
					</div>
					<div className="component-box">
						<div className="row align-items-center">
							<div className="col-lg-6">
								<div className="img-box">
									<figure>
										<img
											src={AboutImage1}
											alt="about-image"
											className="img-fluid"
										/>
									</figure>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="content-wrapper">
									<h2>HaiTrack 2022-2024 strategy</h2>
									<p>
										In 2022 we launched our new three-year strategy,{" "}
										<i>Elevate Our Value</i> , designed to accelerate , expand
										our reach and evolve core capabilities.
									</p>
									<div className="button-group">
										<Link to="#" className="secondary-btn">
											Read More <ArrowIcon />
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="component-box">
						<div className="row align-items-center">
							<div className="col-lg-6">
								<div className="content-wrapper">
									<h2>HaiTrack 2022-2024 strategy</h2>
									<p>
										Our 2021 annual report reflects our key achievements in
										2021; how we serve our customers with{" "}
										<i>expert solutions</i> ; and how we delivered upon our
										2019-2021 strategy.
									</p>
									<div className="button-group">
										<Link to="#" className="secondary-btn">
											Download <ArrowIcon />
										</Link>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="img-box">
									<figure>
										<img
											src={AboutImage2}
											alt="about-image"
											className="img-fluid"
										/>
									</figure>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* About Wolter Sec End Here */}

			{/* Video Sec Start Here */}
			<section className="video-sec">
				<div className="container" style={{ position: "relative" }}>
					<div className="row-bg"></div>
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="video-box">
								<button
									type="button"
									class="btn btn-primary"
									data-bs-toggle="modal"
									data-bs-target="#videoModal"
								>
									<div className="thumbnail">
										<figure>
											<img
												src={VideoThumbnail}
												alt="video-thumbnail"
												className="img-fluid"
											/>
										</figure>
										<div className="button-watch-video">
											<span className="icon">
												<i class="fa-solid fa-play"></i>
											</span>
											<span className="txt">Watch Video</span>
										</div>
									</div>
								</button>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="content-wrapper">
								<h3>The power of purpose</h3>
								<p>
									HaiTrack has a clear purpose that brings our customers and us
									together. We accomplish our mission of delivering deep impact
									when it matters most through protecting people’s health and
									prosperity and contributing to a safe and just society.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Video Sec End Here */}

			{/* News Sec Start Here */}
			<section className="news_sec">
				<div className="container">
					<div className="heading_wrapper both-sides-bar">
						<h3 className="title">News and Press Releases</h3>
						<Link to="#" className="anchor">
							View All Expert Insights
							<ArrowIcon />
						</Link>
					</div>
					<div className="row">
						<News />
					</div>
				</div>
			</section>
			{/* News Sec End Here */}

			{/* Diversity Sec Start Here */}
			<section className="diversity_sec">
				<div className="container">
					<div className="diversity-box">
						<div className="img-box">
							<figure>
								<img
									src={DiversityImage}
									alt="diversity-image"
									className="img-fluid"
								/>
							</figure>
						</div>
						<div className="content-wrapper">
							<h3 className="title">Diversity is the key to success</h3>
							<p>
								As a global company, having a diverse workforce from different
								backgrounds, nationalities, age, expertise, and talent is
								central to our work. Without our diversity, we cannot reflect
								the diversity of our customers and the communities in which we
								live and work.
							</p>
							<div className="button-group">
								<Link to="#" className="secondary-btn">
									Learn More <ArrowIcon />
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Diversity Sec End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default About;
