import React from "react";
import { Link } from "react-router-dom";

const About = () => {
	return (
		<>
			{/* About Sec Start Here */}
			<section className="about_sec">
				<div className="container">
					<div className="title">
						<h3>
							HaiTrack's expert solutions combine expertise with advanced
							technology
						</h3>
					</div>
					<p>
						As a global provider of professional information, software solutions
						and services, our work at HaiTrack helps to protect people's health
						and prosperity and contribute to a safe and just society, while
						building better professionals in business.
					</p>
					<div className="button-group">
						<Link to="#" className="extra-btn">
							More About Our Values
						</Link>
					</div>
				</div>
			</section>
			{/* About Sec End Here */}
		</>
	);
};

export default About;
