import React from "react";

const DemoForm = () => {
	return (
		<>
			{/* Demo Sec Start Here */}
			<section className="demo-form">
				<div className="container">
					<div className="heading-wrapper">
						<h2>Yes, I'd like a demo of HaiTrack Clinical Surveillance</h2>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<form action="">
								<div className="form-group">
									<input
										type="text"
										placeholder="First Name"
										className="form-control"
									/>
								</div>
								<div className="form-group">
									<input
										type="text"
										placeholder="Last Name"
										className="form-control"
									/>
								</div>
								<div className="form-group">
									<input
										type="email"
										placeholder="Email Address"
										className="form-control"
									/>
								</div>
								<div className="form-group">
									<input
										type="text"
										placeholder="Job Title"
										className="form-control"
									/>
								</div>
								<div className="form-group">
									<input
										type="text"
										placeholder="Company Name"
										className="form-control"
									/>
								</div>
								<div className="form-group">
									<input
										type="text"
										placeholder="Phone Number"
										className="form-control"
									/>
								</div>
								<div className="products-check">
									<h5>PRODUCTS:</h5>
									<div className="form-group">
										<input type="checkbox" id="sepsis" />
										<label htmlFor="sepsis">HaiTrack SEPSIS MONITOR</label>
									</div>
									<div className="form-group">
										<input type="checkbox" id="infection" />
										<label htmlFor="infection">
											HaiTrack INFECTION PREVENTION
										</label>
									</div>
									<div className="form-group">
										<input type="checkbox" id="pharmacy" />
										<label htmlFor="pharmacy">HaiTrack PHARMACY</label>
									</div>
									<div className="form-group">
										<input type="checkbox" id="stepward" />
										<label htmlFor="stepward">ANTIMICROBIAL STEWARDSHIP</label>
									</div>
									<div className="form-group">
										<input type="checkbox" id="opioid" />
										<label htmlFor="opioid">OPIOID STEWARDSHIP</label>
									</div>
									<div className="form-group">
										<input type="checkbox" id="dosing" />
										<label htmlFor="dosing">BAYESIAN DOSING</label>
									</div>
									<div className="form-group">
										<input type="checkbox" id="quantifi" />
										<label htmlFor="quantifi">QUANTIFI</label>
									</div>
								</div>
								<div className="privacy">
									<input type="checkbox" id="privacy" />
									<label htmlFor="privacy">
										I WISH TO RECEIVE COMMUNICATIONS ABOUT HaiTrack’S CLINICAL
										SURVEILLANCE AND COMPLIANCE SOLUTIONS, AND I UNDERSTAND AND
										AGREE TO THE PRIVACY POLICY.
									</label>
								</div>
								<div className="button-group">
									<button className="primary-btn">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			{/* Demo Sec End Here */}
		</>
	);
};

export default DemoForm;
