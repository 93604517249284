// HomePage Images
import Logo from "../assets/img/logo.png";
import NewLogo from "../assets/img/new-logo.png";
import HomeBannerImg from "../assets/img/banner-img.jpg";
import ServiceImg1 from "../assets/img/service1.png";
import ServiceImg2 from "../assets/img/service2.jpg";
import ServiceImg3 from "../assets/img/service3.jpg";
import ServiceImg4 from "../assets/img/service4.jpg";
import ServiceImg5 from "../assets/img/service5.jpg";
import InsightImg1 from "../assets/img/insight1.jpg";
import InsightImg2 from "../assets/img/insight2.jpg";
import InsightImg3 from "../assets/img/insight3.jpg";
import InsightImg4 from "../assets/img/insight4.jpg";
import BlogImg1 from "../assets/img/blog1.jpg";
import BlogImg2 from "../assets/img/blog2.jpg";
import BlogImg3 from "../assets/img/blog3.jpg";
import BlogImg4 from "../assets/img/blog4.jpg";
import FooterLogo from "../assets/img/footer-logo.svg";

// Inner Banner Images
import InnerBannerImg from "../assets/img/innerbanner.jpg";
import InnerBannerImg2 from "../assets/img/innerbanner2.jpg";
import InnerBanner3 from "../assets/img/sentri7-banner.jpg";

// Solution Images
import SolutionImg1 from "../assets/img/identity.png";
import SolutionImg2 from "../assets/img/identity2.png";
import SolutionImg3 from "../assets/img/identity3.png";

// Innovation Images
import innovationImg1 from "../assets/img/innovation1.svg";
import innovationImg2 from "../assets/img/innovation2.png";
import innovationImg3 from "../assets/img/innovation3.svg";
import innovationImg4 from "../assets/img/innovation4.svg";
import innovationImg5 from "../assets/img/innovation5.svg";
import innovationImg6 from "../assets/img/innovation6.svg";

// Result Page Images
import ImporveImg1 from "../assets/img/icon1.png";
import ImporveImg2 from "../assets/img/improve-icon2.svg";
import ImporveImg3 from "../assets/img/improve-icon3.svg";
import caseStudyImg from "../assets/img/case_study.png";

// Infection Page Images
import QueryImg1 from "../assets/img/query1.jpg";
import QueryImg2 from "../assets/img/query2.jpg";

// About Page Images
import AboutBannerImage from "../assets/img/aboutbanner.jpg";
import AboutImage1 from "../assets/img/about1.jpg";
import AboutImage2 from "../assets/img/about2.jpg";
import VideoThumbnail from "../assets/img/video-preview.jpg";
import DiversityImage from "../assets/img/diversity.jpg";
import SupportImage from "../assets/img/contact.jpg";
import footerLogo from "../assets/img/footer-logo.png";

// Auth Images
import SoleImage from "../assets/img/soleimg.png";

export {
	Logo,
	NewLogo,
	HomeBannerImg,
	ServiceImg1,
	ServiceImg2,
	ServiceImg3,
	ServiceImg4,
	ServiceImg5,
	InsightImg1,
	InsightImg2,
	InsightImg3,
	InsightImg4,
	BlogImg1,
	BlogImg2,
	BlogImg3,
	BlogImg4,
	InnerBannerImg,
	InnerBannerImg2,
	SolutionImg1,
	SolutionImg2,
	SolutionImg3,
	innovationImg1,
	innovationImg2,
	innovationImg3,
	innovationImg4,
	innovationImg5,
	innovationImg6,
	ImporveImg1,
	ImporveImg2,
	ImporveImg3,
	caseStudyImg,
	QueryImg1,
	QueryImg2,
	AboutBannerImage,
	AboutImage1,
	AboutImage2,
	VideoThumbnail,
	DiversityImage,
	SupportImage,
	SoleImage,
	FooterLogo,
	InnerBanner3,
	footerLogo,
};

// Generic Button Icon
const ArrowIcon = () => {
	return <i className="fa-solid fa-arrow-right"></i>;
};

export default ArrowIcon;
