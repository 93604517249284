import React from "react";
import { Link, useLocation } from "react-router-dom";
import ArrowIcon, {
	SolutionImg1,
	SolutionImg2,
	SolutionImg3,
} from "../../constant/Index";

const Solution = () => {
	const location = useLocation();
	const currentlocation = location.pathname;
	const solutionData = [
		{
			id: 1,
			image: SolutionImg1,
			name: "Identify HAI’s earlier",
			description:
				"By using IPAC’s standard internationally accepted definitions, your staff can identify symptoms quickly and accurately.",
			btnText: "IPAC Definitions",
			btnLink: "#",
		},
		{
			id: 2,
			image: SolutionImg2,
			name: "Real Time Symptoms Tracking",
			description:
				"HaiTrack gives you access to real time reporting, allowing you to see symptoms across an entire facility in seconds. With HaiTrack you know the moment your HCW’s do.",
			btnText: "Real Time Tracking",
			btnLink: "#",
		},
		{
			id: 3,
			image: SolutionImg3,
			name: "24/7 Sepsis Monitoring",
			description:
				"With HaiTrack, Sepsis monitoring is always on, regardless the the infection category your RN’s choose, Sepsis analysis is ALWAYS ON. Ensuring your team is always aware when symptoms align.",
			btnText: "Infection Prevention",
			btnLink: "#",
		},
	];
	return (
		<>
			{solutionData?.map((data, index) => (
				<div
					className={
						currentlocation == "/HaiTrack-clinical-surveillance"
							? "col-lg-4"
							: ""
					}
				>
					<div className="solution-box" key={index}>
						<div className="img-box">
							<figure>
								<img
									src={data?.image}
									alt="solution-image"
									className="img-fluid"
								/>
							</figure>
						</div>
						<div className="content-wrapper">
							<div className="content-box">
								<h3 className="solution-name">{data?.name}</h3>
								<p>{data?.description}</p>
								<div className="button-group">
									<Link to={data?.btnLink} className="secondary-btn">
										{data?.btnText}
										<ArrowIcon />
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			))}
		</>
	);
};

export default Solution;
