import React from "react";
import { Link, useLocation } from "react-router-dom";
import ArrowIcon, {
	BlogImg1,
	BlogImg2,
	BlogImg3,
	BlogImg4,
} from "../../constant/Index";

const News = () => {
	const location = useLocation();
	const currentlocation = location.pathname;
	const newsData = [
		{
			id: 1,
			image: BlogImg1,
			category: "health",
			date: "January 19, 2023",
			name: "Immersive virtual reality training from HaiTrack and Laerdal Medical transports nursing students to a busy hospital floor ",
			description:
				"vrClinicals for Nursing prepares students for medical practice by building clinical judgment and multi-patient prioritization skills.",
		},
		{
			id: 1,
			image: BlogImg2,
			category: "Compliance",
			date: "January 18, 2023",
			name: "HaiTrack's iLien Motor Vehicle wins 2023 BIG Innovation Award ",
			description:
				"iLien for Motor Vehicle, a cloud-based SaaS that delivers lenders a single solution for processing and managing motor vehicle titles and liens, has won a 2023 BIG Innovation Award for product excellence from the Business Intelligence Group.",
		},
		{
			id: 1,
			image: BlogImg3,
			category: "Compliance,Finance,Tax & Accounting",
			date: "January 17, 2023",
			name: "HaiTrack Tax & Accounting names Garrett Hale EVP, Global Operations",
			description: "Hale joins HaiTrack from Assurant",
		},
		{
			id: 1,
			image: BlogImg4,
			category: "Finance",
			date: "January 17, 2023",
			name: "HaiTrack and EY Canada extend strategic alliance",
			description:
				"HaiTrack CCH® Tagetik Corporate Performance Management (CPM) solution to help EY Canada clients streamline financial close, consolidation, regulatory compliance and planning.",
		},
	];
	return (
		<>
			{newsData?.map((data, index) => (
				<div className="col-lg-3">
					<Link to="#" key={index}>
						<div className="blog-box">
							<div className="blog-image">
								<div className="tag">
									<span>Press Release</span>
								</div>
								<figure>
									<img
										src={data?.image}
										alt="blog-images"
										className="img-fluid"
									/>
								</figure>
							</div>
							<div className="content-box">
								<p className="details">
									<span className="category">{data?.category}</span>
									<span className="date">{data?.date}</span>
								</p>
								<h4 className="blog-name">{data?.name}</h4>
								<p className="blog-description">{data?.description}</p>
								<div className="button-group">
									<Link to="#" className="secondary-btn">
										Learn More <ArrowIcon />
									</Link>
								</div>
							</div>
						</div>
					</Link>
				</div>
			))}
		</>
	);
};

export default News;
