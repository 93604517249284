import React from "react";
import { Link } from "react-router-dom";
import { footerLogo } from "../../constant/Index";

const Footer = () => {
	return (
		<>
			{/* Footer Sec Start Here */}
		<footer>
				<div className="container">
					<div className="row">
						<div className="col-md-8">
							<div className="green-line"></div>
							<div className="row">
								<div className="col-md-3">
									<ul className="menus">
										<li className="menu-items">
											<Link to="#" className="menu-link">
												About HaiTrack
											</Link>
										</li>
										<li className="menu-items">
											<Link to="#" className="menu-link">
												Strategy
											</Link>
										</li>
										<li className="menu-items">
											<Link to="#" className="menu-link">
												Our Organization
											</Link>
										</li>
										<li className="menu-items">
											<Link to="#" className="menu-link">
												Management
											</Link>
										</li>
										<li className="menu-items">
											<Link to="#" className="menu-link">
												Supervisory Board
											</Link>
										</li>
										<li className="menu-items">
											<Link to="#" className="menu-link">
												Value Creation
											</Link>
										</li>
										<li className="menu-items">
											<Link to="#" className="menu-link">
												News & Press Releases
											</Link>
										</li>
										<li className="menu-items">
											<Link to="#" className="menu-link">
												Events
											</Link>
										</li>
									</ul>
									<div className="footer-logo">
										<Link to="#" className="anchor">
											<figure>
												<img
													src={footerLogo}
													alt="footer-logo"
													className="img-fluid"
												/>
											</figure>
										</Link>
									</div>
								</div>
								<div className="col-md-3">
									<ul className="menus">
										<li className="menu-items">
											<Link to="#" className="menu-link">
												Solutions Directory
											</Link>
										</li>
										<li className="menu-items">
											<Link to="#" className="menu-link">
												Health
											</Link>
										</li>
										<li className="menu-items">
											<Link to="#" className="menu-link">
												Tax & Accounting
											</Link>
										</li>
										<li className="menu-items">
											<Link to="#" className="menu-link">
												Finance
											</Link>
										</li>
										<li className="menu-items">
											<Link to="#" className="menu-link">
												Compliance
											</Link>
										</li>
										<li className="menu-items">
											<Link to="#" className="menu-link">
												Legal
											</Link>
										</li>
									</ul>
								</div>
								<div className="col-md-3">
									<ul className="menus">
										<li className="menu-items">
											<Link to="#" className="menu-link">
												Expert Insights
											</Link>
										</li>
										<li className="menu-items">
											<Link to="#" className="menu-link">
												Careers
											</Link>
										</li>
										<li className="menu-items">
											<Link to="#" className="menu-link">
												Investors
											</Link>
										</li>
										<li className="menu-items">
											<Link to="#" className="menu-link">
												Sitemap
											</Link>
										</li>
										<li className="menu-items">
											<Link to="#" className="menu-link">
												Site Owner
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="red-line"></div>
							<div className="social-icons">
								<h4 className="title">Follow HaiTrack</h4>
								<ul className="icons">
									<li>
										<Link to="#">
											<i className="fa-brands fa-facebook-f"></i>
										</Link>
									</li>
									<li>
										<Link to="#">
											<i className="fa-brands fa-twitter"></i>
										</Link>
									</li>
									<li>
										<Link to="#">
											<i className="fa-brands fa-linkedin-in"></i>
										</Link>
									</li>
									<li>
										<Link to="#">
											<i className="fa-brands fa-youtube"></i>
										</Link>
									</li>
								</ul>
							</div>
							<div className="right-content">
								<h5>When you have to be right</h5>
							</div>
						</div>
					</div>
				</div>
			</footer>
			{/* Footer Sec End Here */}

			{/* Copyright Sec Start Here */}
			<div className="copyright_sec">
				<div className="container">
					<ul className="sitesetting">
						<li>
							<Link to="#">Terms of Use</Link>
						</li>
						<li>
							<Link to="#">Privacy & Cookies</Link>
						</li>
					</ul>
					<div className="copyright-content">
						<p>
							© 2023 HaiTrack N.V. and/or its subsidiaries. All rights reserved.
						</p>
					</div>
				</div>
			</div>
			{/* Copyright Sec End Here */}
		</>
	);
};

export default Footer;
